<template>
  <b-card>
    <b-overlay rounded="sm" opacity="0.8">
      <b-card class="card-company-table">
        <template>
          <b-row class="match-height">
            <b-col lg="11">
              <h3>{{ form.userUid ? "Editing User: " + userRow.fullName : "Creating User" }}</h3>
            </b-col>
          </b-row>
          <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
            <b-row class="match-height">
              <b-col lg="4">
                <b-card style="max-width: 80%" class="mb-2" bg-variant="light-secondary" border-variant="secondary">
                  <b-card-text>
                    <b-tabs v-model="tabIndex" pills vertical nav-wrapper-class="w-100">
                      <b-tab disabled>
                        <template #title>
                          <b>DATA</b>
                        </template>
                      </b-tab>
                      <b-tab active>
                        <template #title> <feather-icon icon="InfoIcon" /> User Info </template>
                      </b-tab>
                      <b-tab>
                        <template #title> <feather-icon icon="GlobeIcon" /> Facilities & Orgs </template>
                      </b-tab>
                      <b-tab disabled title-link-class="tab-margin-top">
                        <template #title>
                          <br />
                          <b>ACTIONS</b>
                        </template>
                      </b-tab>
                      <b-tab @click="saveEdited()">
                        <template #title> <feather-icon icon="SaveIcon" /> Save & return </template>
                      </b-tab>
                      <b-tab @click="$bvModal.show('bv-modal-example')">
                        <template #title> <feather-icon icon="LogOutIcon" /> Close without saving </template>
                      </b-tab>
                    </b-tabs>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col lg="8">
                <template v-if="tabIndex === 1">
                  <b-form :key="updateFormKey">
                    <b-form-group id="input-group-1" label-for="input-imageurl" label-cols="2" label-align="right">
                      <template #label>
                        <b>User Name:</b>
                      </template>
                      <b-form-input id="input-imageurl" maxlength="500" v-model="form.username" type="url" placeholder="Enter username" required></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-2" label-cols="2" label-align="right">
                      <template #label>
                        <b>Email:</b>
                      </template>
                      <b-form-input id="input-articleurl" maxlength="500" v-model="form.email" type="url" placeholder="Enter email address" required></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-3" label-cols="2" label-align="right">
                      <template #label>
                        <b>Full Name:</b>
                      </template>
                      <b-form-input id="input-headline" maxlength="100" v-model="form.fullName" placeholder="Enter full name" required></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-5" label-cols="2" label-align="right">
                      <template #label>
                        <b>Phone Number:</b>
                      </template>
                      <b-form-input id="input-summary" maxlength="100" rows="5" v-model="form.phone" placeholder="Enter phone number" required></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-5" label-cols="2" label-align="right">
                      <template #label>
                        <b>Metrc API Key:</b>
                      </template>
                      <b-form-input id="input-summary" maxlength="100" rows="5" v-model="form.metrcApiKey" placeholder="Enter metrc API key" required></b-form-input>
                    </b-form-group>
                    <br />
                    <b-form-group id="input-toggles" label=" " label-cols="2" label-align="right">
                      <b-form-checkbox v-model="form.isSupportAgent">Support Agent?</b-form-checkbox>
                      <br />
                      <b-form-checkbox v-model="form.isCompassion">Compassion Access?</b-form-checkbox>
                      <br />
                      <b-form-checkbox v-model="form.isCompassionOperator">Compassion Operator?</b-form-checkbox>
                      <br />
                      <b-form-checkbox v-model="form.isCompassionAdmin">Compassion Admin?</b-form-checkbox>
                      <br />
                      <div class="text-danger">Contact DEV support for admin permissions</div>
                    </b-form-group>
                    <br />
                  </b-form>
                </template>
                <template v-if="tabIndex === 2">
                  <b-form-group label="Organization" label-cols="2" label-for="facilitiesForItem" label-align="right">
                    <template #label>
                      <b>Facilities:</b>
                    </template>
                    <b-form-select id="facilitiesForItem" v-model="form.selectedFacility" :options="facilitiesFiltered()" @input="onSelectedFacility">
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- Please select a Facility --</b-form-select-option>
                      </template>
                    </b-form-select>

                    <b-list-group flush :key="updateFacListKey">
                      <b-list-group-item v-for="item in form.facilities" :key="item.facilityUid">
                        {{ item.name }} - {{ item.licenseNumber }}

                        <feather-icon icon="XCircleIcon" size="18" class="text-danger" v-b-tooltip.hover.right title="Remove facility" @click="onRemoveFacility(item)" />
                      </b-list-group-item>
                    </b-list-group>
                  </b-form-group>
                  <b-form-group label="Organization" label-cols="2" label-for="orgForItem" label-align="right">
                    <template #label>
                      <b>Organization:</b>
                    </template>
                    <b-form-select id="orgForItem" value-field="OrganizationId" text-field="name" v-model="form.orgObject" :options="getAllOrgs">
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- Please select an Organization --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </template>
              </b-col>
            </b-row>
          </b-overlay>
        </template>
      </b-card>
    </b-overlay>

    <b-modal id="bv-modal-example" centered hide-header @ok="onClose()" @cancel="tabIndex = 1">
      <div class="d-block text-center">Closing without saving will discard any changes.</div>
    </b-modal>
  </b-card>
</template>

<script>
import { getFacilityList, getLoading, updateUser, getAllOrgs, saveEditUserItem, loadFacilitiesForUser, loadAllOrgs } from "@/views/users/userRequests";
import { onMounted, ref, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { useRouter } from "@core/utils/utils";
export default {
  name: "CreateUser",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  props: {
    userRow: null
  },
  setup(props, { root, emit }) {
    const DEFAULT_FORM = {
      userUid: "",
      username: "",
      email: "",
      fullName: "",
      phone: "",
      metrcApiKey: "",
      organizationUid: "",
      isSupportAgent: false,
      isCompassion: false,
      isCompassionOperator: false,
      isCompassionAdmin: false,
      facilityUids: [],
      facilities: [],
      selectedFacility: null,
      orgObject: null,
      defaultOrg: ""
    };
    const form = DEFAULT_FORM;

    const { router } = useRouter();

    let updateFacListKey = ref(0);
    let updateFormKey = ref(0);

    const tabIndex = ref(1);

    const facilitiesFiltered = () => {
      return getFacilityList.value.map((p) => {
        return {
          value: p,
          text: p.name
        };
      });
    };

    const onSelectedFacility = () => {
      if (!form.facilityUids) {
        form.facilityUids = [];
      }
      if (form.facilityUids.includes(form.selectedFacility.facilityUid)) {
        return;
      }
      form.facilities.push(form.selectedFacility);
      form.facilityUids.push(form.selectedFacility.facilityUid);
      updateFacListKey.value++;
    };

    const onRemoveFacility = (item) => {
      console.log(item);
      form.facilities = form.facilities.filter((f) => f.facilityUid !== item.facilityUid);
      console.log(form.facilities);
      form.facilityUids = form.facilityUids.filter((f) => f !== item.facilityUid);
      console.log(form.facilityUids);
      updateFacListKey.value++;
    };

    const saveEdited = async () => {
      updateFormKey.value++;
      let pd = {};
      pd.username = form.username;
      pd.userUid = form.userUid || null;
      pd.email = form.email;
      pd.fullName = form.fullName;
      pd.phone = form.phone;
      pd.metrcApiKey = form.metrcApiKey;
      pd.organizationUid = form.organizationUid || null;
      pd.isSupportAgent = form.isSupportAgent;
      pd.isCompassion = form.isCompassion;
      pd.isCompassionOperator = form.isCompassionOperator;
      pd.isCompassionAdmin = form.isCompassionAdmin;
      pd.facilityUids = form.facilityUids;
      if (form.orgObject) {
        pd.organizationUid = form.orgObject;
      }
      try {
        await saveEditUserItem(pd);
      } finally {
        onClose();
      }
    };
    const onClose = () => {
      router.back();
    };

    onMounted(async () => {
      tabIndex.value = 1;
      if (props.userRow) {
        console.log("assigning user info", props.userRow);
        form.userUid = props.userRow.userUid;
        form.username = props.userRow.username;
        form.email = props.userRow.email;
        form.fullName = props.userRow.fullName;
        form.phone = props.userRow.phone;
        form.metrcApiKey = props.userRow.metrcApiKey;
        form.organizationUid = props.userRow.organizationUid;
        form.isSupportAgent = props.userRow.supportAgent;
        form.isCompassion = props.userRow.supportDonation;
        form.isCompassionOperator = props.userRow.compassionOperator;
        form.isCompassionAdmin = props.userRow.compassionAdmin;
        form.facilityUids = [];
        for (const x of props.userRow.facilities) {
          form.facilityUids.push(x.facilityUid);
        }
        form.facilities = props.userRow.facilities;
      }
      await loadFacilitiesForUser();
      await loadAllOrgs();
      if (props.userRow.organizationUid) {
        let orgObj = getAllOrgs.value.find((f) => f.OrganizationId === props.userRow.organizationUid);
        if (orgObj) {
          form.orgObject = orgObj.OrganizationId;
        }
      }
      updateFormKey.value++;
    });

    return {
      getFacilityList,
      getLoading,
      updateUser,
      form,
      getAllOrgs,
      tabIndex,
      updateFacListKey,
      updateFormKey,
      onRemoveFacility,
      facilitiesFiltered,
      onSelectedFacility,
      saveEdited,
      onClose
    };
  }
};
</script>

<style scoped>
.tab-margin-top {
  margin-top: 30px;
}
</style>
